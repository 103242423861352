/* .dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
 
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
}



 */


 .dashboard-container {
    display: grid;
    grid-template-columns: 1fr 400px 1fr; /* Two columns with a fixed width for the middle image */
    gap: 20px;
    padding: 10px;
    margin-top: 20px;

}

.left-cards, .right-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .dashboard-container {
        grid-template-columns: 1fr; /* Stacks the content on smaller screens */
    }

    .image-container {
        margin: 20px 0; /* Space for the image */
    }
}
