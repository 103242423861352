/* PoojaBooking.css */
.ticket-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    border: 2px solid #2c3e50;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    /* font-family: 'Georgia', serif; */
    width: 70%;
    margin: 0 auto;
  }
  
  .ticket-title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .ticket-container p {
    font-size: 18px;
    line-height: 1.6;
    color: #34495e;
  }
  
  .ticket-container h3 {
    font-size: 24px;
    margin-top: 20px;
    color: #2c3e50;
    border-top: 2px solid #2c3e50;
    padding-top: 10px;
  }
  
  .ticket-container strong {
    color: #2980b9;
  }
  