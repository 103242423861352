/* CategoryManagement.css */
body {
    background-color: #f8f9fa;
}
.card-header
{
        border-color: #ff7b00;
}
.container {
    margin-top: 20px;
}

.card {
    margin-bottom: 20px;
}

.table th, .table td {
    vertical-align: middle;
}

.table .btn {
    padding: 0.375rem 0.75rem;
}

.pagination {
    justify-content: flex-end;
}


/* Floating label container */
.floating-label-container {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .floating-input {
    border: 1px solid #000000;
    border-radius: 0.375rem;
    padding: 0.75rem 1rem;
    width: 100%;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
  }
  
  .floating-label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 1rem;
    color: #6c757d;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
  
  .floating-input:focus {
    border-color: #c2eaf8;
    outline: #ced4da;
  }
  
  .floating-input:focus + .floating-label,
  .floating-input:not(:placeholder-shown) + .floating-label {
    top: 0;
    font-size: 0.85rem;
    background-color: rgb(186, 221, 252);
    color: #007bff;
  }
  
  .floating-input:focus + .floating-label {
    color: #000000;
  }
  