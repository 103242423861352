/* 
.app {
    display: flex;
    position: relative;
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px; 
    height: 100%;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    transition: left 0.3s ease, width 0.3s ease;
    padding-top: 50px;
    box-shadow: 4px 0px 10px rgba(162, 160, 156, 0.3); 
    overflow: hidden;
  }
  
  .sidebar.open {
    left: 0;
    width: 230px; 
  }
  
  .sidebar.closed {
    left: 0;
    width: 90px; 
  }


  .sidebar .h3 {
    transition: all 0.3s ease; 
  }


  .sidebar.closed .h3 {
    height: 70px; 
  }
  
  .sidebar.open .h3 {
    height: 100px; 
  }



  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .sidebar ul h3{
    height: 50px;
  }
  
  .sidebar ul li svg {
    margin-right: 10px;
  }
  
  .sidebar ul li span {
    display: inline-block;
    margin-left: 10px;
  }
  
  .content {
    margin-left: 80px;
    padding: 25px;
    transition: margin-left 0.3s ease;
  }
  
  .content.shifted {
    margin-left: 240px;
  }

  
  .toggle-btn {
    position: fixed;
    top: 20px;
    left: 20px;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 60px; 
    }
  
    .sidebar.open {
      width: 60px; 
    }
  
    .sidebar ul li span {
      display: none; 
    }
  
    .content {
      margin-left: 60px;
    }
    .toggle-btn {
        display: none;   
      }
  }


  .HeaderImag{
    width: 1300px;
    height: 150px;
  } */


  :root {
    --primary-color: #4a90e2;
    --bg-color: #f4f7fa;
    --text-color: #333;
    --sidebar-bg: #ffffff;
    --sidebar-hover: #e6f0ff;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  
  .sidebar {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--sidebar-bg);
    overflow-x: hidden;
    transition: 0.3s;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }
  
  .sidebar-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
  }
  .h3
  {
    height: 105px;
  }
  .sidebar-header h3 {
    margin: 0;
    height: 100px;
    font-size: 1.2em;
    color: var(--primary-color);
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: rgb(152, 130, 2);
    font-size: 25px;
    cursor: pointer;
    transition: 0.2s;
  }
  
  .toggle-btn:hover {
    color:red;
  }
  
  .sidebar a {
    padding: 5px 25px;
    text-decoration: none;
    font-size: 16px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    transition: 0.2s;
  }
  
  .sidebar a:hover {
    background-color: var(--sidebar-hover);
    color: var(--primary-color);
  }
  
  .sidebar a i {
    min-width: 30px;
    font-size: 20px;
  }
  
  #main {
    transition: margin-left 0.3s;
    padding: 20px;
    margin-left: 250px;
  }
  
  .sidebar.closed {
    width: 70px;
  }
  
  /* .sidebar.closed .sidebar-header h3 {
    display: none;
  } */
  .sidebar.closed .h3 {
    display: none;
  }

  .sidebar.closed a span {
    display: none;
  }
  
  .sidebar.closed ~ #main {
    margin-left: 70px;
  }
  
  @media screen and (max-width: 768px) {
    .sidebar {
      width: 70px;
    }
  
    .sidebar .sidebar-header h3 {
      display: none;
    }
  
    .sidebar a span {
      display: none;
    }
  
    #main {
      margin-left: 70px;
    }
  
    .sidebar.open {
      width: 250px;
    }
  
    .sidebar.open .sidebar-header h3 {
      display: block;
    }
  
    .sidebar.open a span {
      display: inline;
    }
  
    .sidebar.open ~ #main {
      margin-left: 250px;
    }
  }


  .HeaderImag{
    height: 110px;
    width: 100%;
    
    object-fit: fill; 
  }
  

  /******************************/
  /* Media Queries */

/* For devices smaller than 768px (Mobile) */
@media (max-width: 768px) {
    .HeaderImag {
      height: 90px; 
      width: 100%;
     
      object-fit: fill; 
    }
  }
  
  /* For devices between 768px and 1024px (Tablets) */
  @media (min-width: 768px) and (max-width: 1024px) {
    .HeaderImag {
      height: 100px; /* Adjust the height for tablets */
      object-fit: fill;
    }
  }
  







  